html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html,
body,
#root,
.container {
  height: 100%;
  margin: 0;
}

.loading {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading progress {
  margin-left: auto;
  margin-right: auto;
}
